.rounded-xl {
  border-radius: 2rem;
}

.text-primary {
  color: #0050ff;
}

.fixed-navbar {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 96px;
  top: 0;
  z-index: 1;
}

.bg-primary {
  background-color: #0050ff;
}

html {
  font-family: "Source Sans Pro", sans-serif;
}
